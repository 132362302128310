import FormInput from '@/components/molecules/FormInput';
import { DEFAULT_US_ZIP_CODE } from '@/lib/constants';
import { states } from '@/lib/seo/us-locations/states';
import { MapPinIcon } from '@heroicons/react/24/outline';
import { useEffect } from 'react';

export interface LocationAutoCompleteInputProps {
  zipCode?: string;
  setZip: (zip: string) => void;
  setAddress?: (address: string | undefined) => void;
  onBlur?: (zip: string) => void;
  onAutoComplete?: (zip: string, address: string) => void;
  message: string;
  useGeolocation: () => void;
  label?: string;
  invalid: boolean;
  isGooglePlacesLibraryLoaded: boolean;
}

const LocationAutoCompleteInput: React.FC<LocationAutoCompleteInputProps> = ({
  zipCode,
  setZip,
  setAddress,
  onBlur,
  onAutoComplete,
  message,
  useGeolocation,
  label = 'Location',
  invalid,
  isGooglePlacesLibraryLoaded,
}) => {
  useEffect(() => {
    if (!isGooglePlacesLibraryLoaded) {
      return;
    }
    const address1Field = document.getElementById(
      'address-input'
    ) as HTMLInputElement;

    if (address1Field) {
      const autocomplete = new google.maps.places.Autocomplete(address1Field, {
        componentRestrictions: { country: ['us', 'ca'] },
        fields: ['address_components'],
      });

      autocomplete.addListener('place_changed', async () => {
        const place = autocomplete.getPlace();
        let postcode = '';
        let address = '';
        let city = '';
        let state = '';
        if (place.address_components) {
          place.address_components.forEach((component) => {
            const componentType = component.types[0];
            switch (componentType) {
              case 'postal_code':
                postcode = component.short_name;
                break;
              case 'sublocality_level_1':
                address = `${component.short_name}, `;
                break;
              case 'neighborhood':
              case 'locality':
              case 'political':
              case 'natural_feature':
                city = component.long_name;
                if (!address) {
                  address = `${component.long_name}, `;
                }
                break;
              case 'administrative_area_level_1':
                state = component.short_name;
                address += `${component.short_name}, `;
                break;
              case 'country':
                address += `${component.short_name}`;
                break;
              default:
                break;
            }
          });
          if (!postcode) {
            if (city && state) {
              const apiUrl = `https://api.zippopotam.us/us/${state}/${city}`;
              try {
                const response = await fetch(apiUrl);
                if (response.ok) {
                  const data = await response.json();
                  if (data.places && data.places.length > 0) {
                    postcode = data.places[0]['post code'];
                  }
                }
              } catch (error) {
                postcode = '';
              }
            } else if (!city && state) {
              postcode = states[state.toLowerCase()].postalCode;
            } else {
              postcode = DEFAULT_US_ZIP_CODE;
            }
          }
          setZip(postcode);
          setAddress?.(address);
          onAutoComplete?.(postcode, address);
        }
      });
    }
  });
  return (
    <FormInput
      id="address-input"
      value={zipCode || ''}
      onChange={(zip) => {
        setZip(zip);
        setAddress?.(undefined);
      }}
      placeholder={'City or Zip Code'}
      label={label}
      errorMessage={message}
      invalid={invalid}
      icon={<MapPinIcon className="w-[20px] cursor-pointer" />}
      handleIconClick={useGeolocation}
      onBlur={() => onBlur?.(zipCode || '')}
    />
  );
};

export default LocationAutoCompleteInput;
